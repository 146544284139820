* {
  font-family: courier;
}
.App-header {
  background-color: #00000045;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

a {
  color: #fff;
  text-decoration: none;
}
strong {
  text-decoration: underline;
}
ul {
  list-style: none;
}
h1 {
  color: #d62a60;
}
h1, p {
  text-align: center;
}

body {
  background-image: url('/bg.jpg');
  background-size: cover;
}